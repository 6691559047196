import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Input, MaskedTextBox,RadioButton,Slider  } from "@progress/kendo-react-inputs";
import { Error, Hint } from "@progress/kendo-react-labels";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsNotification from "generic-components/BuzopsNotification";
import { TenantService } from "services/tenant/index.service";
import { MemberType } from "utils/form-utils";
import { NameValidator, childEmailValidator, emailValidator, mobileValidator } from "validators/validator";
import { GetLocalStore } from "utils/storage";
import { ClassService } from "services/class/index.service";
import { Dialog,DialogActionsBar } from "@progress/kendo-react-dialogs";
import { FaEdit } from "react-icons/fa";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import TagsComponent from "./../Tags";
import UpdateSubmemberEmailDialog from "../UpdateEmailSubmember";
import { getFullImageUrl } from "utils";
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnRemoveEvent,
} from "@progress/kendo-react-upload";
import Camera from "../camera"
import Cropper from 'react-easy-crop';
import { getCroppedImg } from "utils/utils";
import utils from "utils/utils";

const CreateClient = (props: any) => {
  const service = new TenantService();
  const tagRef = useRef();
  const IsAffiliateFeatureEnabled = GetLocalStore("Configuration")?.IsAffiliateFeatureEnabled
  const [btnLoading, setBtnLoading] = useState<any>(false);
  const [saveDisabled, setSaveDisabled] = useState<any>(false);
  const [submit, setsubmit] = useState<any>(false);
  const [message, setMessage] = useState("");
  const [userDetails, setUserDetails] = useState<any>({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    ParentMemberId: props?.UserMemberId,
    MemberType: { id: 1, text: "Family Member" },
    UserMemberId: "",
    UserAssociateId: 0,
    UserId: 0,
  });
  const [notification, setNotification] = useState({
    error: false,
    success: false,
  });
  const userSessionInfo = GetLocalStore("userDetails");
  const [timer, setTimer] = useState<any>();
  const classService = new ClassService();

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [isStaff, setIsStaff] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [showUpdateEmailDialog, setShowUpdateEmailDialog] =
    useState<boolean>(false);
  const [newPhotoStatus, setNewPhotoStatus] = useState<any>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<any>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<any>(false);
  const [photoUploadPref, setphotoUploadPref] = React.useState("2");
  const [snapStringValue, setSnapStringValue] = React.useState("");
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [snapString, setSnapString] = React.useState("");
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null); // Change to appropriate type if needed
  const [cropOpen ,setCropOpen]=useState<any>(false);
  
  useEffect(() => {
    let timer = 1000;
    if (notification.error) {
      timer = 3000;
    }
    let timer1 = setTimeout(() => {
      setNotification({ ...notification, error: false, success: false });
    }, timer);
    return () => {
      clearTimeout(timer1);
    };
  }, [notification.error, notification.success]);

  useEffect(() => {
    if (props?.editItem) {
      updateUserDetails(props?.editItem);
    }
  }, [props?.editItem]);
  const updateUserDetails = (dataItem: any) => {
    setUserDetails({
      ...userDetails,
      FirstName: dataItem?.FirstName,
      UserId: dataItem?.UserId,
      LastName: dataItem?.LastName,
      Email: dataItem?.Email,
      Phone: dataItem?.Phone,
      Photo:dataItem?.Photo,
      ParentMemberId: dataItem?.ParentId,
      UserMemberId: dataItem?.UserMemberId,
      UserAssociateId: dataItem?.UserAssociateId,
      MemberType: MemberType?.filter(
        (i: any) => i?.id === dataItem?.MemberType
      )?.[0],
      Tags:dataItem?.Tags
    });
    setNewPhotoStatus(false);
  };

  const showErrorMessage = (key: any, mainKey: any) => {
    if (submit) {
      if (key === "MemberType" && !userDetails?.MemberType?.id) {
        return <Error>{`${mainKey} is required`}</Error>;
      } else if (!userDetails?.[key] || userDetails[key].length <= 0) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
    }
    return <></>;
  };

  const handleNotification = (type: string) => {
    setNotification({ ...notification, [type]: false });
  };

  const handleValidation = () => {
    if (
      (userDetails?.MemberType?.id === 1 && mobileValidator(userDetails?.Phone)) ||
      (userDetails?.MemberType?.id === 1 ? emailValidator(userDetails?.Email) : childEmailValidator(userDetails?.Email)) ||
      NameValidator(userDetails?.FirstName, "First Name") ||
      NameValidator(userDetails?.LastName, "Last Name")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    setsubmit(true);
    const check = handleValidation();
    if (check) {
      const {
        FirstName,
        LastName,
        Email,
        Phone,
        AccessTagId,
        UserMemberId,
        ParentMemberId,
      } = userDetails;
      const payload:any = {
        FirstName,
        LastName,
        Email,
        Phone,
        ParentMemberId: !props?.editItem ? props?.UserMemberId : ParentMemberId,
        MemberType: userDetails?.MemberType?.id,
        AccessTagId,
        UserMemberId: !props?.editItem ? undefined : UserMemberId,
        UserId: userDetails.UserId,
        Tags: userDetails?.Tags,
        Photo:userDetails?.Photo
      };
      if(props?.staffPage){
        const tags = tagRef?.current?.getTags() || [];
        if(tags){
          payload.Tags = tags?.join();
        }
      }
      setBtnLoading(true);
      const res = await service?.saveUserInfo(payload);
      setBtnLoading(false);
      if (res?.ResponseCode === 100) {
        const successMsg = "Client Created Successfully";
        setMessage(successMsg);
        setNotification({ ...notification, success: true, error: false });
        setsubmit(false);
        props?.handleClose(true);
      } else {
        const errorMsg = res?.ErrorMessages?.[0] || "Error in Creating Client";
        setMessage(errorMsg);
        setNotification({ ...notification, error: true, success: false });
      }
    }
  };
  const handleCancel = () => {
    props?.handleClose();
  };

  const validateEmail = async (value: string) => {
    const resp = await classService.validateEmail(value);
    const ParentMemberId = !props?.editItem ? props?.UserMemberId : userDetails?.ParentMemberId
    if (resp.Item.UserId !== 0) {
      if (resp.Item.UserMemberId !== null) {
        if (!resp.UserMemberIsActive && resp.Item.ParentUserMemberId && ParentMemberId === resp.Item.ParentUserMemberId) {
          setShowActivateModal(true);
          setUserData(resp.Item);
          setSaveDisabled(true);
          setIsStaff(false);
        } else {
          setSaveDisabled(true);
          setIsStaff(false);
          setValidationError('The email address provided is already taken. Please provide an alternate email address.')
        }
      } else {
        if (props?.staffPage) {
          setUserData(resp.Item);
          setShowEmailModal(true);
          setIsStaff(true);
          setValidationError('')
        } else {
          setSaveDisabled(true);
          setIsStaff(false);
          setValidationError('The email address provided is already taken. Please provide an alternate email address.')
        }
      }
    } else {
      setShowEmailModal(false);
      setUserData(undefined);
      setIsStaff(false);
      setValidationError('')
    }
  };

  const handleChange = (val: any, name: string) => {
    let value = val
    if (name === "Phone" && val === "(___) ___-____") {
      value = ""
    }
    setUserDetails({
      ...userDetails,
      showMessage: false,
      [name]: value,
    });

    if (name === "Email") {
      clearTimeout(timer);
      setSaveDisabled(false);

      const newTimer = setTimeout(() => {
        validateEmail(value);
      }, 500);
      setTimer(newTimer);
    }
  };

  const activateMember = async () => {
    const res = await classService.activateMember(userData.UserMemberId);
    if (res?.ResponseCode === 100) {
      const successMsg = "Activated Successfully";
      setMessage(successMsg);
      setNotification({ ...notification, success: true, error: false });
      setsubmit(false);
      props?.handleClose();
    } else {
      const errorMsg = res?.ErrorMessages?.[0] || "Unable to activate member";
      setMessage(errorMsg);
      setNotification({ ...notification, error: true, success: false });
    }
  };

  const updateUserFields = () => {
    setUserDetails({
      ...userDetails,
      FirstName: userData?.FirstName,
      LastName: userData?.LastName,
      Phone: userData?.Phone || "",
      UserId: userData?.UserId || 0,
      UserAssociateId: userData?.UserAssociateId
    });
  };
  const handleDeleteDialog = () => {
    if (userDetails?.Photo) {
      setShowDeleteDialog(true);
    }
    return;
  };
  const toggleDeleteDialog = () => {
    setShowDeleteDialog(false);
  };
  const toggleUplaodDialog = () => {
    setShowUploadDialog(false);
    setSnapStringValue("");
  };
  const handleTakeASnapClick = () => {
    setphotoUploadPref("1");
  };
  const handleUploadAFileClick = () => {
    setphotoUploadPref("2");
  };
  const handleCapture = (e: any) => {
    setSnapString(e);
    setSnapStringValue(e);
  };
  const handleRefreshCamera = (e: any) => {
    setphotoUploadPref("2");
  };
  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    if (event.affectedFiles.length > 0) { 
        const file = event.affectedFiles[0].getRawFile?.(); 
        if (file) { 
          const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];          
          if (allowedFileTypes.includes(file.type)) {
              const reader = new FileReader();          
              reader.onloadend = () => {
                  setImage(reader.result as string); 
                  setCropOpen(true); 
              };             
              reader.readAsDataURL(file); 
          } else {
              console.error("Unsupported file type. Please upload .jpeg, .jpg, or .png files only.");
          }
        } else {
            console.error("File is undefined"); 
        }
    }
  };
  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };
  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };
  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);
  const deleteImage = (status: boolean) => {
    if (status) {
      setUserDetails({
        ...userDetails,
        Photo: null,
      });
      if (files.length > 0) {
        setFiles([]);
        setOriginalFiles([]);
      }
    }
    setNewPhotoStatus(false);
    toggleDeleteDialog();
  };
  const UploadImage = () => {
    if (photoUploadPref === "2") {
      if (files?.length === 0) {
        setMessage("Please select image to upload");
        setNotification({ ...notification, error: true });
        return true;
      }
      const allowdFileTypes = [".jpeg", ".jpg", ".png"];
      let check = false;
      files.length > 0 &&
        files.forEach((file: any) => {
          if (!allowdFileTypes.includes(file?.Extension.toLowerCase())) {
            const errorMsg = "Please upload allowed File Type";
            setMessage(errorMsg);
            setNotification({ ...notification, error: true });
            check = true;
            return true;
          }
          if (file?.Size > 500000) {
            const errorMsg = "Please upload image less than 500KB";
            setMessage(errorMsg);
            setNotification({ ...notification, error: true });
            check = true;
            return true;
          }
        });
      if (check) {
        return true;
      }
      setUserDetails({
        ...userDetails,
        Photo: files[0]?.ImageAsBase64,
      });
      setZoom(1);
      setCrop({ x: 0, y: 0 });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    } else if (photoUploadPref === "1") {
      setUserDetails({
        ...userDetails,
        Photo: snapString,
      });
      setZoom(1);
      setCrop({ x: 0, y: 0 });
      setNewPhotoStatus(true);
      setShowUploadDialog(false);
    }
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const handleUpload = async () => {
    if (croppedAreaPixels && image) {
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels);
        if (typeof croppedImage === 'string' && croppedImage.startsWith('blob:')) {
          const response = await fetch(croppedImage);
          const blob = await response.blob();
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setFiles((prevFiles) => {
              const updatedFile = {
                ...prevFiles[0],
                ImageAsBase64: base64data, 
              };
              return [updatedFile, ...prevFiles.slice(1)];
            });
            setCropOpen(false);
          };  
        } 
      } catch (error) {
        console.error("Error cropping image", error);
      }
    }
  };
  
  const renderUploadDialog = () => {
    return (
      <Dialog
        className="bz-book-Appoinment-dialog bz-familyMember-dialog"
        title={"Upload a Profile Photo"}
        onClose={() => toggleUplaodDialog()}
        width={"34rem"}
      >
        <div style={{ textAlign: "justify" }} className="k-grid bzc-grid">
          <div className="row">
            <div className="col-sm-12">
              <div className="sales-radio-button-headers">
                <RadioButton
                  name="uploadPhoto2"
                  value="2"
                  checked={photoUploadPref === "2"}
                  label="Upload a File"
                  onClick={handleUploadAFileClick}
                />
                {"   "}
                <RadioButton
                  name="uploadPhoto1"
                  value="1"
                  checked={photoUploadPref === "1"}
                  label="Take a Snap"
                  onClick={handleTakeASnapClick}
                />
              </div>
              <hr />
              <div className="k-form-field">
                {photoUploadPref === "2" && (
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 500000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                )}
                {photoUploadPref === "1" && (
                  <div>
                    <Camera
                      handleCameraCapture={handleCapture}
                      handleRefreshCamera={handleRefreshCamera}
                      handleDisable={setSnapStringValue}
                    />
                  </div>
                )}
                <Hint>
                  {
                    "Only JPEG and PNG files are allowed. Allowed size below 500KB"
                  }
                </Hint>
                {(image && cropOpen) && (
                    <Cropper
                      image={image}
                      crop={crop}
                      zoom={zoom}
                      aspect={10 / 10}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />     
                )}
              </div>
            </div>
          </div>
        </div>
        {!cropOpen && (<Button
          className={"float-right mt-3"}
          onClick={() => toggleUplaodDialog()}
        >
          Cancel
        </Button>)}
        {/* <Button
          className={"float-right mt-3"}
          primary={true}
          onClick={() => UploadImage()}
        >
          Upload
        </Button> */}
        {cropOpen && (
          <div className="d-flex flex-column align-items-center" >     
            <Slider
            className="mt-3"
              min={1}
              max={10}
              defaultValue={1}
              value={zoom}
              onChange={(e) => {
                setZoom(e.value); // Extract the value from the event object
              }}
            />
            <Button
              primary={true}
              onClick={() => handleUpload()}
              style={{
                zIndex: 10,
              }}
            >
              Set Image
            </Button>
          </div>
        )}
        {!cropOpen && (
          photoUploadPref === "1" ? (
            <Button
              className="float-right mt-3"
              primary={snapStringValue?true:false}
              onClick={() => UploadImage()}
              disabled={!snapStringValue}
            >
              Upload
            </Button>
          ) : (
            <Button
              className={"float-right mt-3"}
              primary={true}
              onClick={() => UploadImage()}
            >
              Upload
            </Button>
          )
        )}            
      </Dialog>
    );
  };
  const renderDeleteDialog = () => {
    return (
      <Dialog onClose={() => toggleDeleteDialog()}>
        <p className={"dialogContainer"}>
          Are you sure you want to remove the photo?
        </p>
        <DialogActionsBar>
          <Button onClick={() => deleteImage(false)}>{"No,keep it."}</Button>
          <Button primary={true} onClick={() => deleteImage(true)}>
            Yes
          </Button>
        </DialogActionsBar>
      </Dialog>
    );
  };
  const DummyImage = "/images/user.png";
  return (
    <>
      <h5 className="mb-3 bz_fs_1_5">
        <button
          className="btn btn-primary bz-back-btn btn-sm"
          onClick={() => props?.handleClose()}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        &nbsp;
      </h5>
      {showUploadDialog && renderUploadDialog()}
      {showDeleteDialog && renderDeleteDialog()}
      <Hint>Add a family member or a child to the primary account.</Hint>
      <hr />
      {(!(
        userDetails?.UserAssociateId === 0 ||
        userDetails?.UserAssociateId === null
      ) ||
        userDetails?.showMessage) && (
          <div className="alert alert-warning py-2 px-3" role="alert">
            {`This profile is already associated with a ${IsAffiliateFeatureEnabled ? "staff/affiliate" : "staff"} in this club. Profile
          changes cannot be made. Changes can only be made from the ${IsAffiliateFeatureEnabled ? "Staff/Affiliate" : "Staff"} page.`}
          </div>
        )}
      <div className="bz-subsection-row bz-myprofile-container">
        <div className="left-section">
          <div className="row bz-mb-2_5rem">
            <div className="col-sm-9 col-xl-10 col-xxl-9 order-2 order-sm-1">
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Client Type*</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <DropDownList
                    data={MemberType}
                    textField="text"
                    className="form-control col-sm-10 col-xl-10"
                    dataItemKey="id"
                    name="MemberType"
                    value={userDetails?.MemberType}
                    onChange={(e) => handleChange(e.value, "MemberType")}
                    disabled={
                      !(
                        userDetails?.UserAssociateId === 0 ||
                        userDetails?.UserAssociateId === null
                      )
                    }
                  />
                  {showErrorMessage("MemberType", "Client Type")}
                </div>
              </div>
              <div className={`${NameValidator(userDetails?.FirstName, "First Name") ? 'bz-content-text-row align-items-start' : 'bz-content-text-row'}`}>
                <div className="bz-content-title text-right">First Name*</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Input
                    autoComplete={"off"}
                    name="FirstName"
                    id="FirstName"
                    className="form-control col-sm-10 col-xl-10"
                    value={userDetails?.FirstName}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "FirstName")
                    }
                    disabled={
                      isStaff ||
                      !(
                        userDetails?.UserAssociateId === 0 ||
                        userDetails?.UserAssociateId === null
                      )
                    }
                  />
                  {submit &&
                    NameValidator(userDetails?.FirstName, "First Name") && (
                      <Error>
                        {NameValidator(userDetails?.FirstName, "First Name")}
                      </Error>
                    )}
                </div>
              </div>
              <div className={`${NameValidator(userDetails?.LastName, "Last Name") ? 'bz-content-text-row align-items-start' : 'bz-content-text-row'}`}>
                <div className="bz-content-title text-right">Last Name*</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Input
                    autoComplete={"off"}
                    name="LastName"
                    id="LastName"
                    className="form-control col-sm-10 col-xl-10"
                    value={userDetails?.LastName}
                    onChange={(e: any) =>
                      handleChange(e.target.value, "LastName")
                    }
                    disabled={
                      isStaff ||
                      !(
                        userDetails?.UserAssociateId === 0 ||
                        userDetails?.UserAssociateId === null
                      )
                    }
                  />
                  {submit &&
                    NameValidator(userDetails?.LastName, "Last Name") && (
                      <Error>
                        {NameValidator(userDetails?.LastName, "Last Name")}
                      </Error>
                    )}
                </div>
              </div>
              <div className={`${mobileValidator(userDetails?.Phone) ? 'bz-content-text-row align-items-start' : 'bz-content-text-row'}`}>
                <div className="bz-content-title text-right">
                  Phone Number
                  {userDetails?.MemberType?.id === 1 && <span>*</span>}
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <FieldWrapper>
                    <MaskedTextBox
                      mask="(999) 000-0000"
                      name="Phone"
                      id="Phone"
                      className="col-sm-10 col-xl-10"
                      value={userDetails?.Phone}
                      onChange={(e: any) =>
                        handleChange(e.target.value, "Phone")
                      }
                      disabled={
                        isStaff ||
                        !(
                          userDetails?.UserAssociateId === 0 ||
                          userDetails?.UserAssociateId === null
                        )
                      }
                    />
                    {userDetails?.MemberType?.id === 1 &&
                      submit &&
                      mobileValidator(userDetails?.Phone) && (
                        <Error>{mobileValidator(userDetails?.Phone)}</Error>
                      )}
                  </FieldWrapper>
                </div>
              </div>
              <div className={`${(userDetails?.MemberType?.id === 1
                ? emailValidator(userDetails?.Email)
                : childEmailValidator(userDetails?.Email)) ? 'bz-content-text-row align-items-start mb-1' : 'bz-content-text-row mb-1'}`}>
                <div className="bz-content-title text-right align-sm-self-center">
                  {" "}
                  Email
                  {userDetails?.MemberType?.id === 1 && <span>*</span>}
                </div>
                <span className="bz-colon text-center">
                  :
                </span>
                <div className="bz-content-body">
                  <div
                    className={`input-group col-sm-10 col-xl-10 p-0 ${props?.editItem
                      ? "edit-email-group"
                      : "non-editable-email"
                      }`}
                  >
                    <FieldWrapper>
                      <Input
                        autoComplete={"off"}
                        name="Email"
                        id="Email"
                        className="form-control"
                        value={userDetails?.Email}
                        onChange={(e: any) =>
                          handleChange(e.target.value, "Email")
                        }
                        disabled={
                          !(
                            userDetails?.UserAssociateId === 0 ||
                            userDetails?.UserAssociateId === null
                          ) || props?.editItem
                        }
                      />
                      {submit
                        ? ((userDetails?.MemberType?.id === 1
                          ? emailValidator(userDetails?.Email)
                          : childEmailValidator(userDetails?.Email)) ||
                          validationError) && (
                          <Error>
                            {(userDetails?.MemberType?.id === 1
                              ? emailValidator(userDetails?.Email)
                              : childEmailValidator(userDetails?.Email)) ||
                              validationError}
                          </Error>
                        )
                        : validationError && <Error>{validationError}</Error>}
                    </FieldWrapper>
                    {props?.editItem && (


                      <div className="input-group-append">
                        <BuzopsTooltip
                          html={
                            <span
                              title="Update Email"
                            >
                              <Button
                                disabled={
                                  !(
                                    userDetails?.UserAssociateId === 0 ||
                                    userDetails?.UserAssociateId === null
                                  )
                                }
                                onClick={() => setShowUpdateEmailDialog(true)}
                                className="email-edit-icon"
                              >
                                <FaEdit />
                              </Button>
                            </span>
                          }
                        />

                      </div>
                    )}
                  </div>
                </div>
              </div>
              {console.log(props?.staffPage,'props?.staffPage')}
              {props?.staffPage &&  <div className="bz-content-text-row mt-2">
                  <div className="bz-content-title text-right">
                    Tags
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <TagsComponent ref={tagRef} tagData={userDetails?.Tags || ""} />
                  </div>
                </div>}
              {userDetails?.MemberType?.id !== 1 && (
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <div className="row">
                      <Hint className="mt-0 col-sm-10 col-xl-10">
                        If no email is provided, all communications will be
                        directed to the primary email address
                      </Hint>
                    </div>
                  </div>
                </div>
              )}
              <div className="bz-content-text-row mt-4 pt-2">
                <div className="bz-content-title text-right"></div>
                <span className="bz-colon text-center"></span>
                <div className="bz-content-body">
                  <Button onClick={() => handleCancel()} className="ml-0">
                    Cancel
                  </Button>
                  <BuzopsButton
                    disabled={
                      btnLoading ||
                      saveDisabled ||
                      !(
                        userDetails?.UserAssociateId === 0 ||
                        userDetails?.UserAssociateId === null
                      )
                    }
                    type={"submit"}
                    loading={btnLoading}
                    label={"Save"}
                    onClick={() => handleSubmit()}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-xl-2 col-xxl-3 order-1 order-sm-2">
                <div className="bz-Manage-User-Img d-flex justify-content-center pb-4">
                  <div className="mx-auto ProfilePicOptions">
                    <img
                      width={120}
                      src={
                        !newPhotoStatus
                          ? userDetails?.Photo
                            ? getFullImageUrl(userDetails?.Photo)
                            : DummyImage
                          : userDetails?.Photo
                      }
                      className="rounded-circle img-circle"
                    />
                    <span
                      className="bz-delete-userImg customtooltip"
                      onClick={() => {
                        handleDeleteDialog();
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                      <span className="tooltiptext">Delete</span>
                    </span>
                    <span
                      className="bz-upload-userImg customtooltip"
                      onClick={() => {
                        setShowUploadDialog(true);
                      }}
                    >
                      <i className="fa fa-camera" aria-hidden="true"></i>
                      <span className="tooltiptext">Upload</span>
                    </span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>

      {showEmailModal && (
        <Dialog
          className="bz-book-Appoinment-dialog bz-selectClient-dialog "
          title={`Email Exists`}
          width={"450px"}
          onClose={() => setShowEmailModal(false)}
          style={{ zIndex: 9999 }}
        >
          <p>
            {`An existing ${IsAffiliateFeatureEnabled ? "Staff/Affiliate" : "Staff"} with the provided email address already exists.
            Please click "Continue" to populate the existing details associated
            with this email.`}
          </p>
          <div className="mt-3 bzc-row">
            <div className="bzc-edit-session-options-row-buttons">
              <Button
                onClick={() => {
                  setShowEmailModal(false);
                  setUserDetails({
                    ...userDetails,
                    showMessage: true,
                  });
                  setUserData(undefined);
                  setSaveDisabled(true);
                }}
                className="ml-0"
              >
                Cancel
              </Button>
              <BuzopsButton
                onClick={() => {
                  setShowEmailModal(false);
                  updateUserFields();
                }}
                label={"Continue"}
                autoFocus={true}
              />
            </div>
          </div>
        </Dialog>
      )}

      {showUpdateEmailDialog && (
        <UpdateSubmemberEmailDialog
          setShowUpdateEmailDialog={setShowUpdateEmailDialog}
          setMessage={setMessage}
          setNotification={setNotification}
          notification={notification}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
        />
      )}
      {showActivateModal && (
        <Dialog
          className="bz-selectClient-dialog bz-edit-email-dialog"
          title={"Activate Prospect"}
          onClose={() => {
            setShowEmailModal(false);
            setShowActivateModal(false);
          }}
          style={{ zIndex: 9999 }}
        >
          <div className="bzc-row">
            <p className="mb-1">
              This email ID and associated prospect is archived. Click
              "Continue" to activate this archived prospect.
            </p>
            <div className="bzc-edit-session-options-row-buttons">
              <Button
                onClick={() => {
                  setShowActivateModal(false);
                }}
                className="ml-0"
              >
                Cancel
              </Button>
              <BuzopsButton
                onClick={() => {
                  setShowEmailModal(false);
                  activateMember();
                }}
                label={"Continue"}
                className="mr-0"
              />
            </div>
          </div>
        </Dialog>
      )}
      <BuzopsNotification
        notification={notification}
        message={message}
        handleNotification={(type: any) => handleNotification(type)}
      />
    </>
  );
};

export default CreateClient;
